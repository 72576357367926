import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Config from '../config'

const REDIRECT_URL = Config.ZONES_PAGE_REDIRECT_URL

export default function Zones () {
  return (
    <Layout>
      <section className='well m-mb-0'>
        <div className='row align-center justify-center' style={{ minHeight: 400 }}>
          <div className='col text-center'>
            <div className="inner">
              <Helmet>
                <meta httpEquiv='refresh' content={`0; url=${REDIRECT_URL}`} />
                <link rel='canonical' href='https://trexity.com/zones' />
              </Helmet>
              <h1>Delivery Zones</h1>
              <p>This page has moved. This page will be redirected in a few seconds.</p>
              <a className='button-link button-link-brand' href={REDIRECT_URL}>Navigate To Page Now</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
